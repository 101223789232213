import React from 'react';
import { graphql } from 'gatsby';

import { isSameCountry, isSameRegion } from '@core/helpers/country-validations';
import truncate from '@core/helpers/truncate';
import { CountryContext } from '@core/providers/country/country-provider';
import DomPurify from '@core/helpers/dom-purify';
import ParagraphComponent from '@core/helpers/paragraph-helpers';
import ContentBlur from '@core/helpers/content-blur';

import Breadcrumb from '@shared/breadcrumbs/breadcrumbs';

import { Graphql } from '@models/graphql';
import { Country } from '@models/country';

import Hero from '@shared/hero/hero';

const { whitepaper: crumbs } = require('@config/breadcrumbs');

const MAX_BODY_LENGTH = 600;

type WhitepaperProps = {
  language: string
  data: {
    whitepaper: Graphql
  }
};

export default function WhitepaperPage({ data, language }: WhitepaperProps): React.ReactElement {
  const { whitepaper } = data;

  const paragraphs = whitepaper?.relationships?.paragraphs?.map(
    (node) => ParagraphComponent({ ...node, path: whitepaper.path }, language)
  );

  const getContent = (isValidCountry: boolean) => {
    const body = whitepaper?.wysiwyg?.processed || '';
    const partialContent = (
      <>
        { body && (
          <div className="container text-black wysiwyg my-5">
            <DomPurify text={isValidCountry ? body : truncate(body, MAX_BODY_LENGTH)} />
          </div>
        )}
      </>
    );

    if (!isValidCountry) {
      return partialContent;
    }

    return (
      <>
        {partialContent}
        {paragraphs && paragraphs.length > 0 && (
          <div className="page-components-container">{paragraphs}</div>
        )}
      </>
    );
  };

  const getContentByCountry = (country: Country) => {
    const countries = whitepaper?.relationships?.countries || [];
    const forceShowContent = true;
    const isValidCountry = isSameCountry(country, countries) || isSameRegion(country, countries) || forceShowContent;
    const content = getContent(isValidCountry);

    if (!isValidCountry) {
      return <ContentBlur content={content} />;
    }

    return content;
  };

  return (
    <CountryContext.Consumer>
      {
        (country: Country) => (
          <div className="whitepaper--page min-h-100vh">
            {whitepaper?.relationships?.hero && (
              <div className="hero-container mb-5">
                <Hero node={whitepaper.relationships.hero} />
              </div>
            )}
            <Breadcrumb
              crumbs={crumbs.concat([{ label: whitepaper.title, link: '#' }])}
            />
            {getContentByCountry(country)}
          </div>
        )
      }
    </CountryContext.Consumer>
  );
}

export const query = graphql`
  query($id: String!) {
    whitepaper: nodeWhitepaper(id: { eq: $id }) {
      id
      title
      wysiwyg: body {
        processed
      }
      path {
        alias
        langcode
      }
      relationships {
        countries: field_countries {
          country: name
          countryCode: field_country_code
          tid: drupal_internal__tid
          langcode
          status
        }
        hero: field_hero {
          ...HeroParagraph
        }
        paragraphs: field_content_main {
          type: __typename
          ...CtaParagraph
          ...HeroParagraph
          ...HeroSliderParagraph
          ...BannerParagraph
          ...BannerTalentParagraph
          ...BannerAdvParagraph
          ...QuoteSliderParagraph
          ...QuoteParagraph
          ...IntroTextParagraph
          ...IntroBlockParagraph
          ...TimelineParagraph
          ...CardsParagraph
          ...BoxesParagraph
          ...GridParagraph
          ...ClientsParagraph
          ...TechnologiesParagraph
          ...TabsParagraph
          ...TableParagraph
          ...WysiwygParagraph
          ...ViewParagraph
          ...FeaturedContentParagraph
          ...BannerFormParagraph
          ...BannerTalentApplyParagraph
          ...EmergencyModalParagraph
          ...BannerVideoParagraph
          ...WebFormParagraph
          ...CollageParagraph
          ...FabParagraph
          ...ImageSliderParagraph
          ...OfficesParagraph
        }
      }
    }
  }
`;
